// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $( '.header' );
var bp_small = 768;
var bp_medium = 992;
var class_active = 'active';
var class_disabled = 'disabled';
var class_hidden = 'overflow-hidden';
var $this = null;
var $containers = $( 'html,body' );


// --- COMPONENTS, PARTS --- //
$( document ).ready( function(){
	// ---  COMPONENTS --- //
	fn_components_fullscreen_navigation();
	fn_components_gallery();
	fn_components_slider();
	// fn_components_select();
	// fn_components_calendar();
	fn_components_counter();




	// ---  PARTS --- //
	fn_parts_dropdown();
	// fn_parts_navigation_scroll_resize();

});

// --- SCROLL EVENT --- //
$( document ).scroll( function () {
	// fn_parts_navigation_scroll_resize();
});

// --- RESIZE EVENT --- //
$( window ).resize( function(){
	fn_checkNavigationTrigger();
	// fn_parts_navigation_scroll_resize();
});

// --- LOAD EVENT --- //
$( window ).bind( 'load' , function () {
	fn_parts_dots();
});