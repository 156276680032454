//====================================================
//  Function: Counter
//====================================================
function fn_components_counter(){
	if ($( '.js-counter' ).length){
		var waypoint = new Waypoint({
			element: document.getElementById('counter'),
			handler: function(direction) {
				$('.js-number').each(function () {
				    $(this).prop('Counter',0).animate({
				        Counter: $(this).attr('data-number')
				    }, {
				        duration: 2000,
				        easing: 'swing',
				        step: function (now) {
				        	if ($(this).attr('data-sign')){
				            	$(this).text(Math.ceil(now) + '' + $(this).attr('data-sign'));
				        	} else {
				            	$(this).text(Math.ceil(now));
				        	}
				        }
				   	});
				});
		  	},
 			offset: '80%'
		})
	}
}

