//====================================================
//  Function: Fullscreeen navigation
//====================================================
function fn_components_fullscreen_navigation(){
	if ($( '.navigation' ).length && $(window).width() < bp_medium){
		$( '.navigation-trigger').on('click', function(e){
			$(this).closest( '.navigation' ).toggleClass( 'is-collapse' );
			e.preventDefault();
		});
		fn_checkNavigationTrigger();
	}
}

function fn_checkNavigationTrigger(){
	(function($){
		if($(window).width() > bp_medium && $( '.navigation' ).hasClass( 'is-collapse' )){
			$( '.navigation' ).removeClass( 'is-collapse' );
		}
	})(jQuery);
}