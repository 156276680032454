//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	fn_classic_slider();
}



function fn_classic_slider(){
	if ($('.js-slider-classic').length) {
		$('.js-slider-classic').slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-sipka-reference"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-sipka-reference"></i></span>',
			pauseOnHover: false,
			pauseOnFocus: false,
			// fade: true,
			variableWidth: false,
			mobileFirst: true
		});
	}   
	
}
